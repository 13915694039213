import styled from 'styled-components';
import warning2 from '../assets/icons/warning2.svg';
import done from '../assets/icons/done.svg';
import info from '../assets/icons/info.svg';

export const SmallHintText = styled.p`
    color: ${({ color }) => color || '#8A99A6'};
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 0px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    
    &.done {
        position: relative;
        color: rgba(38, 40, 35, 1);
        padding-left: 20px;
        padding-top: 7px;
        &:before {
            content: ' ';
            position: absolute;
            top: 9px;
            left: 3px;
            background: url("${done}");
            background-repeat: no-repeat;
            background-size: contain;
            width: 14px;
            height: 12px;
            display: block;
        }
    }

    &.info {
        position: relative;
        color: rgba(38, 40, 35, 1);
        padding-left: 20px;
        padding-top: 7px;
        &:before {
            content: ' ';
            position: absolute;
            top: 9px;
            left: 3px;
            background: url("${info}");
            background-repeat: no-repeat;
            background-size: contain;
            width: 14px;
            height: 12px;
            display: block;
        }
    }
`;

export const MediumHintText = styled.p`
    color: #8A99A6;
    font-family: 'Manrope';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 476px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};

    a {
        text-decoration: underline;
        font-weight: ${({ linkWeight }) => linkWeight || "700"};
        color: #8A99A6;
    }
    
    &.withWarning {
        position: relative;
        color: rgba(38, 40, 35, 1);
        padding-left: 20px;
        padding-top: 7px;
        &:before {
            content: ' ';
            position: absolute;
            top: 10px;
            left: 3px;
            background: url("${warning2}");
            background-repeat: no-repeat;
            background-size: contain;
            width: 14px;
            height: 12px;
            display: block;
        }
        
        a {
            color: rgba(38, 40, 35, 1);
            font-weight: 400;
        }
    }
`;

export const BigHintText = styled.p`
    color: #8A99A6;
    font-family: 'Manrope';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 530px;
    margin-top: ${({ marginTop }) => marginTop || '0px'};

    a {
        text-decoration: underline;
        font-weight: 700;
        color: #8A99A6;
    }
`;

export const Text = styled.p`
    color: #292929;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: ${({ marginBottom }) => marginBottom || '19px'};
    margin-top: ${({ marginTop }) => marginTop || '0px'};
    padding: ${({ padding }) => padding || '0px'};
    
    a {
        color: #292929; 
        text-decoration: underline;
    }
`;
