import styled from 'styled-components';
import logo from '../assets/icons/logo.svg';

const Logo = styled.a`
    display: block;
    width: 71px;
    height: 30px;
    background-image: url('${logo}');
    background-size: cover;

    margin-left: 22px;

    @media only screen and (max-width: 441px) {
        width: 60px;
        height: 26px;

        ${({ isOpen }) => isOpen ? 'display: none;' : 'display: block;'}
    }
`;

export default Logo;
