import styled from 'styled-components';
import React from 'react';
import Link from '../components/Link';
import freedaysimg from '../assets/icons/freedays.svg';
import {useTranslation} from "react-i18next";

const FreeDaysBannerWrapper = styled.div`
    position: relative;
    background: #A4C3EC;
    width: 530px;
    height: auto;
    padding: 12px 24px;
    margin: ${({ margin }) => margin || '36px 0px'};
    border-radius: 4px;
    
    &:after {
        content: ' ';
        position: absolute;
        top: 20px;
        right: 0px;
        background: url("${freedaysimg}");
        background-repeat: no-repeat;
        width: 144px;
        height: 182px;
        display: block;

        @media only screen and (max-width: 441px) {
            width: 137px;
            height: 110px;
            bottom: 0;
            top: auto;
        }
    }

    @media only screen and (max-width: 441px) {
        width: 340px;
    }
`;

const FreeDaysBannerTitle = styled.h2`
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    max-width: 368px;

    @media only screen and (max-width: 441px) {
        max-width: 220px;
    }
`;

const FreeDaysBannerDescription = styled.p`
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    @media only screen and (max-width: 441px) {
        max-width: 220px;
    }
`;

const FreeDaysBanner = ({ margin }) => {
    const { t } = useTranslation();
    return(
        <FreeDaysBannerWrapper margin={margin}>
            <FreeDaysBannerTitle>
                {t('AddFreeDays')}
            </FreeDaysBannerTitle>
            <FreeDaysBannerDescription>
                {t('AddFreeDaysDescription')}
            </FreeDaysBannerDescription>
            <Link href="/referal">
                {t('FreeDaysDetails')}
            </Link>
        </FreeDaysBannerWrapper>
    )
}

export default FreeDaysBanner;
