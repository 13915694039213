
import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Text } from '../components/Text';
import { Button } from '../components/Button';
import settings from '../settings.json';
import gull from '../assets/icons/Gull.svg';
import { LoginInput } from '../components/Input';
import Link from '../components/Link';
import PasswordInput from "../components/PasswordInput";
import {ErrorLabel} from "../components/Label";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 292px;
    height: 100vh;
    margin: 0 auto;

    z-index: 4;

    @media only screen and (max-width: 441px) {
        margin-top: 64px;
    }
`;

const LoginWrapper = styled.div`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    flex-grow: 1;
    text-align: center;
    display: flex;
    text-align: center;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: ${({ margin }) => margin || 0};
`;

export const Login = () => {
    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [error, setError] = useState('');
    const { t,  i18n } = useTranslation();

    const handleLoginChange = (e) => {
        e.preventDefault();
        setLogin(e.target.value);
    }

    const handlePassChange = (e) => {
        e.preventDefault();
        setPass(e.target.value);
    }

    const handleLogin = (e) => {
        e.preventDefault();
        e.target.disabled = true;
        const submitButton = document.getElementById("submitButton")
        axios.post(`/Auth/Login`, JSON.stringify({login: login, pass: pass}), {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            if ((res.status === 200 || 302) && (!res.data.error)) {
                window.location.reload();
            } else {
                setError(t("AuthError"))
            }
            submitButton.disabled = false;
        }).catch((err) => {
            setError(t("AuthError"))
            submitButton.disabled = false;
        })

    }

    return (
        <Wrapper>
            <LoginWrapper>
                <Container margin="0px 0px 30px 0px"><img src={gull} /></Container>
                <Container><Text>{t("AuthText")}</Text></Container>
                <Container>
                    <form>
                        <LoginInput onChange={(e) => handleLoginChange(e)} placeholder={t("Login")} margin="0px 0px 10px 0px" />
                        <PasswordInput value={pass} onChange={(e) => handlePassChange(e)} placeholder={t("Password")} />
                        {error && <ErrorLabel>{error}</ErrorLabel>}
                        <Button id="submitButton" type="submit" onClick={(e) => handleLogin(e)}>{t("Enter")}</Button>
                    </form>
                    <Link href={i18n.language === 'en' ? settings.forgotPassUrlEn : settings.forgotPassUrl}>{t("ForgotPassword")}</Link>
                </Container>
            </LoginWrapper>
        </Wrapper>
    );
}
