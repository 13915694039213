import React from 'react';
import styled from 'styled-components';
import { NavMenu } from './NavMenu';

const Wrapper = styled.div`
    display: flex;
    flex-flow: row;
    position: relative;

    @media only screen and (max-width: 441px) {
        flex-flow: column;
    }
`;

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    margin-left: 164px;

    ${({ centered }) => centered && `display: flex;
        align-items: center;`}

    @media only screen and (max-width: 441px) {
        margin-left: 0px;
    }
`;

export const Layout = ({ children, centered, isXrayAllowed }) => {
    return (
        <Wrapper>
            <NavMenu isXrayAllowed={isXrayAllowed} />
            <Container centered={centered}>
                {children}
            </Container>
        </Wrapper>
    );
}
