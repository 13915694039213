import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import settings from '../settings.json';
import { Subscription } from '../components/Subscription';
import { BigHintText } from '../components/Text';
import { BuyButton } from '../components/Button';
import { Context} from "../App";
import buymore from '../assets/icons/buymore.svg';
import Icon from "../components/Icon";
import WarningBanner from "../components/WarningBanner";
import calculateStatus from "../helpers/calculateStatus";
import FreeDaysBanner from "../components/FreeDaysBanner";

const Wrapper = styled.div`
    padding: 37px 97px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;

    @media only screen and (max-width: 441px) {
        padding: 35px 20px;
        margin-top: 64px;
    }
`;

const Footer = styled.div`
    margin-top: auto;
`;

export const Home = ({ token, email, isXrayAllowed, fetchSubscriptions, subscriptions }) => {
    const { t, i18n } = useTranslation();

    return (
        <Wrapper>
            {/*<WarningBanner language={i18n.language} />*/}
            <FreeDaysBanner language={i18n.language} margin="0px 0px 36px 0px" />
            {subscriptions ? subscriptions.map((sub, index) =>
                <Subscription
                    key={sub.id}
                    id={sub.id}
                    region={sub.regionCode}
                    paymentCurrency={sub.currencySymbol}
                    paymentSum={sub.sum}
                    paymentDate={sub.nextTransactionDate}
                    monthNumPeriod={sub.monthNumPeriod}
                    dataLimit={sub.dataLimit}
                    length={subscriptions.length}
                    index={index}
                    token={token}
                    email={email}
                    endDate={sub.endDate}
                    isHanging={sub.isHanging}
                    gate={sub.gate}
                    language={i18n.language}
                    isXrayAllowed={isXrayAllowed}
                    referralPromocode={sub.referralPromocode}
                    isPending={sub.isPending}
                    nextTrafficReset={sub.nextTrafficReset}
                    transferRequestedTime={sub.transferRequestedTime}
                    transferFinishedTime={sub.transferFinishedTime}
                    fetchSubscriptions={fetchSubscriptions}
                    status={calculateStatus(sub.transferRequestedTime, sub.transferFinishedTime)}
                />) : null}

            <a style={{ width: "274px", color: "#262823" }} href={`${settings.buyLink}${(i18n.language === 'ru' || i18n.language === 'ru_RU') ? settings.ruVPN : settings.engVPN}`}><BuyButton><Icon src={buymore}/>{t('BuyAnother')}</BuyButton></a>
            <Footer>
                <BigHintText>
                    {t('Hint1')} <b><a href={`mailto:${settings.supportLink}`}>{settings.supportLink}</a></b>
                </BigHintText>
            </Footer>
        </Wrapper>
    );
}
