import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import settings from '../settings.json';
import money from '../assets/icons/money.svg';
import { Text } from '../components/Text';
import Title from '../components/Title';
import axios from "axios";

const Wrapper = styled.div`
    padding: 37px 97px;
    min-height: 100vh;
    display: flex;
    flex-flow: column;

    @media only screen and (max-width: 441px) {
        padding: 35px 20px;
        margin-top: 64px;
    }
`;

const Description = styled.p`
    max-width: 505px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin: 0;
`;

const Step = styled.h3`
    font-family: Manrope;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 40px;
`;

const StepsWrapper = styled.div`
    margin: 0px 0px;
    margin-top: 47px;
    max-width: 530px;
`;

const StatsWrapper = styled.div`
    background: #A4C3EC;
    width: 170px;
    height: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    border-radius: 4px;
    flex-flow: column wrap;
    padding: 10px 23px;
    
    .stats-money {
        position: relative;
        &:after {
            position: absolute;
            bottom: -45px;
            right: -49px;
            content: ' ';
            width: 62px;
            height: 62px;
            background-image: url("${money}");
            background-repeat: no-repeat;
        }
    }
`;

const Stats = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    margin: 23px 0 47px 0;
`;

const StatsTitle = styled.h3`
    font-family: Stapel;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
`;

export const Referal = ({ token, email }) => {
    const { t, i18n } = useTranslation();

    return (
        <Wrapper>
            <Title>{t('ReferalTitle')}</Title>
            <Description>{t('ReferalDescription')}</Description>
            {/*<Stats>*/}
            {/*    <StatsWrapper>*/}
            {/*        <Description>{t('ReferalStatsGet')}</Description>*/}
            {/*        <StatsTitle>30 дней</StatsTitle>*/}
            {/*        <Description>{t('ReferalStatsFree')}</Description>*/}
            {/*    </StatsWrapper>*/}
            {/*    <StatsWrapper>*/}
            {/*        <Description>{t('ReferalStatsInvite')}</Description>*/}
            {/*        <StatsTitle>1 друга</StatsTitle>*/}
            {/*    </StatsWrapper>*/}
            {/*    <StatsWrapper>*/}
            {/*        <div className="stats-money">*/}
            {/*            <Description>{t('ReferalStatsSave')}</Description>*/}
            {/*        </div>*/}
            {/*    </StatsWrapper>*/}
            {/*</Stats>*/}
            <StepsWrapper>
                <Title>{t('ReferalStepsTitle')}</Title>
                <Step>{t('Step1Referal')} </Step>
                <Step>{t('Step2Referal')} </Step>
                <Text marginBottom="17px">{t('Step2DescriptionReferal')} </Text>
                <Step>{t('Step3Referal')} </Step>
                <Text>{t('Step3DescriptionReferal')} </Text>
            </StepsWrapper>
        </Wrapper>
    );
}
