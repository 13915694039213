import React from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Link from './Link';
import getPlanName from '../helpers/getPlanName';
import planChange from '../assets/icons/planchange.svg'
import Icon from "./Icon";
import FieldLabel from "./FieldLabel";

const Container = styled.div`
    width: 280px;
    height: 60px;
    border-radius: 4px;
    background: ${({ isChosen }) => isChosen ? '#C1FF73' : '#EEEFF4'};

    display: flex;
    flex-flow: row;
    margin-bottom: 7px;
    cursor: pointer;

    @media only screen and (max-width: 441px) {
        width: 335px;
    }

    @media only screen and (max-width: 920px) {
        width: 335px;
    }
`;

const Title = styled.h3`
    color: #262823;
    font-family: Stapel;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const Description = styled.p`
    color: #262823;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-flow: column;
    padding: 9px 0;
    padding-left: 9px;
`;

const PromoWrapper = styled.div`
    display: flex;
    flex-flow: column;
    padding: 9px 0;
    padding-right: 9px;
    margin-left: auto;
`;

const PlanInfo = styled.p`
    color: #262823;
    font-family: Manrope;
    font-size: 16px;
    font-height: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
`;

const Highlight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FF737E;
    border-radius: 4px;
    height: 19px;
    color: white;
    padding: 4px 10px;
    margin-top: 9px;
    font-family: Manrope;
    font-size: 10px;
    font-weight: 700;
    
`;

const OldPriceContainer = styled.div`
    color: #262823;
    text-align: right;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    text-decoration: line-through;
`;


export const PlanCard = ({ isChosen, dataLimit, monthNumPeriod, sum, currencySymbol, onClick, isHighlighted, oldPrice }) => {
    const { t } = useTranslation();
    return (
        <Container isChosen={isChosen} onClick={onClick}>
            <InfoWrapper>
                <Title>{sum}{'\u00A0'}{currencySymbol} {getPlanName(monthNumPeriod, i18n.language)}</Title>
                <Description>{dataLimit}{t("GbMonth")}</Description>
            </InfoWrapper>
            <PromoWrapper>
                {oldPrice !== 0 && <OldPriceContainer>
                    {oldPrice}{'\u00A0'}{currencySymbol}
                </OldPriceContainer>}
                {isHighlighted && <Highlight>{t("Highlight")}</Highlight>}
            </PromoWrapper>
        </Container>
    )
}

export const CurrentPlan = ({ canChange, id, paymentSum, monthNumPeriod, dataLimit, currencySymbol }) => {
    const { t } = useTranslation();
    return (
        <>
            <FieldLabel>{t("YourPlan")} <b>{paymentSum == 0 ? t('Free') : paymentSum}{paymentSum == 0 ? '' : '\u00A0'}{paymentSum == 0 ? '' : currencySymbol}{paymentSum == 0 ? '' : ` ${getPlanName(monthNumPeriod, i18n.language)}`}, {dataLimit}{t("GbMonth")}</b></FieldLabel>
            {/*{canChange && !isPending ? <Link href={`/change?subId=${id}`} marginTop="13px"><Icon src={planChange} />{t("ChangePlan")}</Link> : null}*/}
        </>
    )
}
