import React, { useEffect, useState, useRef, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Title from '../components/Title';
import Input from '../components/Input';
import { Text } from '../components/Text';
import { MediumHintText, BigHintText } from '../components/Text';
import { PlanCard } from '../components/PlanCard';
import { Button } from '../components/Button';
import { PopUpWrapper, PopUpWindow } from '../components/PopUp';
import { CloseButton } from '../components/Button';
import Link from '../components/Link';
import settings from '../settings.json';
import useStateWithCallback from '../helpers/useStateWithCallback';
import {Context} from "../App";
import {Loader} from "../components/Loader";
import getPlanName from "../helpers/getPlanName";
import i18n from "i18next";

const Wrapper = styled.div`
    padding: 37px 97px;

    @media only screen and (max-width: 441px) {
        padding: 35px 20px;
        margin-top: 64px;
    }
`;

const FormWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-top: 33px;

    @media only screen and (max-width: 920px) {
        flex-flow: column;
    }
`;

const Container = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;

    position: relative;
    padding: 13px 22px;
    height: 100%;
`;

const Cards = styled.div`
    display: flex;
    flex-flow: column;
`;

const Form = styled.form`
    display: flex;
    flex-flow: column;
    margin-left: 18px;
    max-width: 273px;

    @media only screen and (max-width: 920px) {
        margin-left: 0px;
        max-width: 335px;
        margin-top: 8px;
    }
`;

const LinksContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
`;


export const PlanChange = () => {
    const inputRef = useRef(null);
    const popupContainerRef = useRef(null);
    const confirmPopupContainerRef = useRef(null);
    const [availableSubs, setAvailableSubs] = useState([]);
    const [id, setId] = useState(0);
    const [plan, setPlan] = useStateWithCallback({});
    const [showLoader, setShowLoader] = useState(false);
    const [promo, setPromo] = useState('');
    const [promoIsValid, setPromoIsValid] = useState(false);
    const [promoIsWrong, setPromoIsWrong] = useState(false);
    const [promoIsLoading, setPromoIsLoading] = useState(false);
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [confirmPopupIsOpen, setConfirmPopupIsOpen] = useState(false);
    const [popupText, setPopupText] = useState('');
    const { subscriptions, setSubscriptions } = useContext(Context);
    const urlParams = new URLSearchParams(window.location.search);
    const subId = urlParams.get('subId');
    const { t } = useTranslation();

    useEffect(() => {
        setId(subId);
        getAvailableSubs(subId);

        const handleClickOutside = (event) => {
            if (popupContainerRef.current && !popupContainerRef.current.contains(event.target)) {
                setPopupIsOpen(false);
                window.location.href = '/';
            }

            if (confirmPopupContainerRef.current && !confirmPopupContainerRef.current.contains(event.target)) {
                setConfirmPopupIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupContainerRef]);

    const handlePopup = (e) => {
        e && e.preventDefault();
        setPopupIsOpen(!popupIsOpen);
        window.location.href = '/';
    }

    const getAvailableSubs = (subId) => {
        axios.get(
            `/api/getavailablesubscriptions?subId=${subId}&promocode=${promo}`
        ).then((res) => {
            setAvailableSubs(res.data.data);
            setPlan(res.data.data[0]);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        submitPromo();
        setConfirmPopupIsOpen(true);
    }

    const submit = (e) => {
        e.preventDefault();
        e.target.disabled = true;
        if(confirmPopupIsOpen) {
            setConfirmPopupIsOpen(false);
            setShowLoader(true);
        }
        const submitCallback = (prevValue, newValue) => {
            axios.post(
                `Api/ChangeSub?oldSubId=${id}&newSubId=${newValue.subId}&promocode=${inputRef.current.value}`
            ).then((res) => {
                if (!res.data.error) {
                    setPopupText(`${t("SuccessPayment")} ${t("NextTransaction")} ${format(new Date(newValue.nextTransactionDate), 'dd.MM.yyyy')}`)
                    setPopupIsOpen(true);
                    setShowLoader(false);
                    localStorage.setItem('subs', JSON.stringify([...subscriptions.filter((ps) => ps.id != id), subscriptions.filter(item => id == item.id)[0] && { ...subscriptions.filter(item => id == item.id)[0], isPending: true }]))
                } else {
                    setPopupText(t("SomethingWentWrong"))
                    setPopupIsOpen(true);
                    setShowLoader(false);
                }
            })
        }

        if (inputRef.current.value !== '' && !promoIsValid) {
            setPromoIsLoading(true);
            submitPromo(submitCallback);
        } else {
            submitCallback({}, plan);
        }
    }

    const submitPromo = (submitCallback) => {
        if(!inputRef.current.value) {
            return;
        }
        setPromoIsLoading(true);
        setPromo(inputRef.current.value.trim());
        inputRef.current.value = inputRef.current.value.trim();

        axios.get(
            `/api/GetChangeSubData?userSubId=${id}&promocode=${inputRef.current.value.trim()}`
        ).then((res) => {
            setPromoIsValid(res.data.data.isValidPromocode);
            setPromoIsWrong(!res.data.data.isValidPromocode);
            setAvailableSubs(res.data.data.availableTariffs);
            if (submitCallback) {
                setPlan(res.data.data.availableTariffs.find(item => plan.subId === item.subId), submitCallback);
            } else {
                setPlan(res.data.data.availableTariffs.find(item => plan.subId === item.subId));
            }
            setPromoIsLoading(false);
        })
    }

    return (
        <Wrapper>
            {showLoader && <Loader />}
            <Title>{t('ChoosePlanTitle')}</Title>
            <FormWrapper onSubmit={e => e.preventDefault()}>
                <Cards>
                    {availableSubs && availableSubs.map((item) =>
                        <PlanCard
                            isChosen={plan.subId === item.subId}
                            onClick={(e) => setPlan(item)}
                            dataLimit={item.dataLimit}
                            monthNumPeriod={item.monthNumPeriod}
                            sum={Number.isInteger(item.sum) ? item.sum : parseFloat(item.sum).toFixed(2)}
                            currencySymbol={item.currencySymbol}
                            id={item.id}
                            key={item.id}
                            isHighlighted={item.isHighlighted}
                            oldPrice={item.oldPrice}
                        />)
                    }
                </Cards>
                <Form>
                    <Input
                        id="promo"
                        outlineColor={promoIsValid && '#c1ff73' || promoIsWrong && '#ff99ab'}
                        isLoading={promoIsLoading}
                        isWrong={promoIsWrong}
                        inputRef={inputRef}
                        submit={(e) => submitPromo(e)}
                        errorHint={t("PromoIsWrong")}
                        placeholder={t("PromoInput")}
                    />
                    <Button
                        type="submit"
                        onClick={(e) => handleSubmit(e)}
                        mobileWidth="335px"
                        tabletWidth="335px"
                        marginBottom="0px"
                        marginTop="12px">{t("Pay")}</Button>
                    <Link
                        centered={true}
                        marginRight="0px"
                        marginTop="6px"
                        href="/">{t("Cancel")}</Link>
                </Form>
            </FormWrapper>
            <MediumHintText marginTop="11px" linkWeight="400">
                <b>{t("SubsRenewal")}</b> <br/>
                <a href={settings.agreement}>{t("UserPolicy")}</a>
            </MediumHintText>

            <BigHintText marginTop="40px">
                {t("ChangePlanHint")}
                <br />
                <br/>
                {t("ChangePlanQuestions")} <a href="mailto:support@papervpn.io">support@papervpn.io</a>
            </BigHintText>
            {popupIsOpen &&
                <PopUpWrapper>
                    <PopUpWindow ref={popupContainerRef}>
                        <Container>
                            <CloseButton onClick={(e) => handlePopup(e)} />
                            <Text padding="0px 15px 0px 0px">{popupText}</Text>
                            <MediumHintText>{t("ShowKeyHint1")} <a href="mailto:support@papervpn.io">support@papervpn.io</a></MediumHintText>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
            {confirmPopupIsOpen &&
                <PopUpWrapper log={console.log(plan)}>
                    <PopUpWindow ref={confirmPopupContainerRef}>
                        <Container>
                            <CloseButton onClick={(e) => setConfirmPopupIsOpen(false)} />
                            <Text padding="0px 15px 0px 0px">{t('ChosenPlan')} {plan.dataLimit}{t('ChosenPlanEnd')}, {Number.isInteger(plan.sum) ? plan.sum : parseFloat(plan.sum).toFixed(2)}{'\u00A0'}{plan.currencySymbol} {getPlanName(plan.monthNumPeriod, i18n.language)}. {t('Transaction')} {Number.isInteger(plan.sum) ? plan.sum : parseFloat(plan.sum).toFixed(2)}{'\u00A0'}{plan.currencySymbol} {t('TransactionEnd')}</Text>
                            <MediumHintText>{t('ChangeNextPayment')} {format(new Date(plan.nextTransactionDate), 'dd.MM.yyyy')}</MediumHintText>
                            <LinksContainer>
                                <Link onClick={() => setConfirmPopupIsOpen(false)}>{t('Back')}</Link>
                                <Link onClick={(e) => submit(e)}>{t('PayChangePlan')}</Link>
                            </LinksContainer>
                        </Container>
                    </PopUpWindow>
                </PopUpWrapper>
            }
        </Wrapper>
    )
}
